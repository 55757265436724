@import url("https://use.typekit.net/mcs5qjn.css");
@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");
@import "./variables";

@import "./chart";

html,body{
  padding: 0;
  margin: 0;
}

* {
  font-family: "proxima-nova";
}
.parent-signature {
  width: 100%;
}

.signature-pad {
  width: 100%;
  //height: 300px;
}
// text align

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

// SVG Loading
div.loading {
  svg {
    width: 30px;
    height: 20px;
  }
}

.row {
  &.full {
    width: 100%;
  }
}

// Forms Components
.form-group {
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  label {
    position: absolute;
    top: -9px;
    background-color: $white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    z-index: 1;
    left: 15px;
    color: #959595;
    font-weight: 500;
  }

  .react-datepicker {
    border: 0;
    box-shadow: 0 0 5px rgba($color: $dark, $alpha: 0.16);
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__tab-loop {
    position: relative;
    z-index: 2;
    bottom: 55px;

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected {
      background-color: $default;
    }

    .react-datepicker-popper[data-placement^="bottom"] {
      .react-datepicker__triangle {
        border-bottom-color: $white;
      }
    }

    .react-datepicker__header {
      background-color: $white;
      border-color: #eee;

      .react-datepicker__header__dropdown {
        display: flex;

        .react-datepicker__month-dropdown-container,
        .react-datepicker__year-dropdown-container {
          display: block;
          flex: 1;
        }
      }

      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__month-year-read-view--down-arrow {
        border-width: 4px;
        margin: 0;
      }
    }
  }
  .autocomplete {
    width: 100%;

    .item-autocomplete {
      span {
        display: none;
      }
    }

    .___optionsDiv___ {
      background-color: #fff;
      box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.16);
      border-radius: 10px;

      div {
        padding: 15px;
        font-weight: 700;
        cursor: pointer;
      }
    }

    .button {
      padding: 0;
      border: 0;
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 9;
      text-align: left;

      &:hover {
        color: $dark;
        background-color: transparent;
      }
    }
  }
  input,
  textarea,
  select {
    border-radius: 3px;
    padding: 16px 15px;
    border: 1px solid $grayMedium;
    width: 100%;
    background-color: $white;
    transition: all 250ms;
    height: 58px;

    &:focus {
      border-color: $default;
    }
  }

  textarea {
    height: auto;
  }

  &.error {
    input,
    select {
      border-color: $error;
    }

    p {
      color: $error;
      margin: 5px 0 0;
    }

    .file-container {
      border-color: $error;

      .file-name {
        color: $error;
      }
    }
  }

  .file-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    height: 45px;

    .file-input {
      border: 1px dashed #ddd;
      border-radius: 4px;
      flex: 1;
      height: 100%;
      padding: 9px 15px;
    }

    .group-icon {
      background-color: $default;
      color: $white;
      height: 100%;
      display: flex;
      flex-direction: row;
      padding: 0 10px;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 5px;
      font-size: 14px;
      margin-right: 10px;
    }

    svg {
      font-size: 16px;
      margin: 0 5px;
    }

    .file-name {
      font-size: 16px;
      color: $dark;
      font-weight: 500;
      white-space: nowrap;
      max-width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .file-control {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .trash {
      border: 0;
      border-radius: 4px;
      background-color: $error;
      height: 100%;
      color: $white;
      padding: 0 10px;
      margin-left: 10px;
      display: none;
    }


    &.active {

      .trash {
        display: inline-block;
      }

      .group-icon {
        background-color: $success;
        border-radius: 100%;
      }

      .file-control {
        pointer-events: none;
      }
    }
  }
}
.fieldset {
  margin-bottom: 15px;
  padding: 0 30px 15px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #eee;

  h4 {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 16px;
  }
}
.form {
  fieldset {
    margin-bottom: 15px;
    padding: 0 30px 15px;
    border-radius: 5px;
    border: 1px solid #eee;

    h4 {
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }

  .border-0{
    border: none;
  }
  .border-r{
    border:none;
    border-right: 1px solid #eee;
  }
  .analise-h4 {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .analise-value-input {
    overflow: hidden;
  }

  &.form-proposal {
    padding: 15px;

    .block {
      position: relative;
    }

    .button-add {
      position: absolute;
      font-size: 14px;
      padding: 10px;
      right: 25px;
      top: 6px;
    }

    h3 {
      font-size: 32px;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 30px;
    }

    .br {
      border-right: 1px solid #eee;
    }

    .value-input {
      overflow: hidden;

      input {
        border: 0;
        font-size: 50px;
        font-weight: 700;
        color: #333;
      }
    }

    .MuiSlider-root {
      height: 14px;

      .MuiSlider-rail,
      .MuiSlider-track {
        height: 14px;
        border-radius: 40px;
      }

      .MuiSlider-rail {
        background: #999;
      }

      .MuiSlider-thumb {
        width: 24px;
        height: 24px;
      }
    }
  }
}

// Buttons Components



button {
  cursor: pointer;
}

.min-150{
  min-width: 150px;
}

.button {
  border-radius: 3px;
  padding: 15px 35px;
  box-shadow: 0 0 0;
  border: 1px solid $dark;
  background: transparent;
  font-size: 16px;
  color: $dark;
  transition: all 250ms;
  align-items: center;
  outline: none !important;

  &.cadastro{
      color: white;
      text-align: center;
      background-color: #febc31;
      font-weight: bold;
      width: 100%;
      //max-width: 480px;
      min-height: 48px;
      padding: 12px 0;
      border: 0;
      border-radius: 30px;
      margin-top: 60px;
      margin-bottom: 15px;
      outline: 0;

    &.success{
      align-self: center;
      width: 200px;
    }
  }


  &:hover,
  &.loading {
    background: $default;
    color: $white;
    border-color: $default;
  }

  svg {
    margin-right: 5px;
  }

  &.default {
    background: $default;
    color: $white;
    border-color: $default;

    &:hover {
      background: $success;
      color: $white;
      border-color: $success;
    }
  }

  &.danger {
    background-color: $error;
    border-color: $error;
    color: #fff;

    &:hover {
      background-color: red;
    }
  }

  &.close {
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: 20px;
    color: #000;
    opacity: 1;
  }

  &.small {
    padding: 8px 15px;

    &.tableAnalise{
      
    }

    &.circle {
      width: 40px;
      height: 40px;
      padding: 0;
    }

    svg {
      margin: 0;
      width: 14px;
      height: 14px;
    }
  }

  &.clean {
    padding: 0;
    border: 0;
    font-size: 22px;

    &:hover {
      background-color: transparent;
      color: $dark;
    }
  }

  &.circle {
    border-radius: 100%;
    display: flex;
    padding: 0 0;
    justify-content: center;
    align-items: center;
  }

  &.icon {
    text-align: center;
    vertical-align: middle;
    border-radius: 100%;
    border-radius: 4px;
    padding: 8px 10px;
    overflow: hidden;
    width: 38px;
    white-space: nowrap;

    &:hover {
      width: 120px;
    }
  }

  &.disabled,
  &.disabled:hover {
    cursor: initial;
    background-color: #ddd;
    border-color: transparent;
    color: #bbb;
  }
}

// Pages custom

#spotlight {
  position: relative;

  .span {
    position: absolute;
  }

  .span-1 {
    top: 50px;
    left: -58px;
    background: url(../images/1.png) center no-repeat;
    width: 58px;
    height: 80px;
  }

  .span-2 {
    top: -80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: url(../images/2.png) center no-repeat;
    width: 120px;
    height: 140px;
  }

  .span-3 {
    top: 0;
    right: -38px;
    background: url(../images/3.png) center no-repeat;
    width: 230px;
    height: 156px;
  }

  .span-4 {
    bottom: 30px;
    right: 30px;
    background: url(../images/4.png) center no-repeat;
    width: 40px;
    height: 40px;
  }
}

#body-main {
  background: #edf0f5;
  width: 100%;
  min-height: 100vh;
  padding: 50px;
}

// Badge
.badge {
  padding: 6px 5px;
  width: 70px;
  &.badge-success {
    background-color: $success;
  }

  &.badge-warning {
    background-color: $warning;
    color: #fff;
  }

  &.badge-pending {
    background-color: $pendente;
    color: $white;
  }

  &.badge-finish {
    background-color: $success;
    color: $white;
  }

  &.badge-cancel {
    background-color: $error;
    color: $white;
  }

  &.badge-delete {
    background-color: $error;
    color: $white;
  }
}

.legends {
  width: 100%;

  .badge {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    padding: 0;
    display: inline-block !important;
    margin-right: 3px;
  }
}

// Message Table
.message-table {
  font-size: 22px;
  color: rgba($color: $dark, $alpha: 0.4);
  text-align: center;
  font-weight: bold;
  display: block;
  text-align: center;
  width: 100%;
  margin: 30px 0;
  text-transform: uppercase;
}

// Calendar
.react-calendar {
  .react-calendar__year-view__months {
    padding: 15px 0;
  }

  .react-calendar__tile {
    background-color: transparent;
    border: 0;
    padding: 10px;
  }

  .react-calendar__month-view__days {
    button {
      transition: all 250ms;

      &:hover {
        background-color: $default;
        color: $white;
        border-radius: 100%;
      }
    }
  }

  .react-calendar__navigation {
    button {
      background-color: transparent;
      border: 0;
    }
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      padding: 0 15px;
      transition: all 250ms;
      border-radius: 100%;
      font-size: 26px;

      &:hover {
        color: $white;
        background: $default;
      }
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__label {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .react-calendar__month-view__days {
    button {
      background-color: transparent;
      border: 0;
      width: 46px;
      height: 46px;

      &.react-calendar__tile--now {
        background-color: $default;
        color: $white;
        border-radius: 100%;
        width: 46px;
        height: 46px;
        flex-basis: initial !important;
        max-width: initial !important;
      }

      &.react-calendar__month-view__days__day--neighboringMonth {
        background-color: #eee;

        &:hover {
          border-radius: 0;
          color: $dark;
        }
      }
    }
  }

  .react-calendar__month-view__weekdays {
    padding: 15px 0;

    .react-calendar__month-view__weekdays__weekday {
      text-align: center;

      abbr {
        text-decoration: none;
      }
    }
  }
}

.container-chart {
  position: relative;
}

// Table
.box-table {
  padding: 0;
  padding: 16px 16px;

  .header {
    margin-bottom: 30px;
    padding: 0 15px;
  }

  .box-body {
    padding: 0;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
  }
}

.rsdt-container {
  width: 100%;
}
.table {
  width: 100%;
  border: 1px;
  margin: 0;

  thead {
    tr {
      border: 0;
      border-radius: 15px;
      height: 48px;
      th {
        padding: 8px;
        color: $grayBold;
        text-align: center;
        font-weight: 700;
        font-size: 15px;
        border: 0;
        background-color: #f3f4fb;

        &:first-child {
          border-radius: 16px 0px 0px 16px;
          //text-align: left;
        }

        &:last-child {
          border-radius: 0px 16px 16px 0px;
          //text-align: left;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 25px 15px;
        color: $grayBold;
        border-bottom: 1px solid #ddd;
        border-top: 0;
        vertical-align: middle;
        text-align: center;

        .group-buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

          .button {
            margin: 0 5px;

            &.small {
              font-size: 14px;
            }
          }
        }

        /*&:first-child {
          //text-align: left;
        }*/
        &:last-child {
          //text-align: left;
          //width: 230px;

          button.signature {
            width: 160px;
          }
        }
      }
      &:last-child {
        td {
          border: 0;
        }
      }
    }
  }
}

// modal

.modal {
  .modal-content {
    .modal-header {
      border: 0;
      background-color: $default;

      .modal-title {
        color: $white;
        font-weight: 700;
        text-transform: uppercase;
      }

      .close {
        text-shadow: 0 0 0;
        opacity: 0.5;
        font-size: 32px;
      }
    }

    .footer {
      padding: 30px 0;

      .button {
        &.default {
          padding: 18px 75px;
          border-radius: 4p;
        }
      }
    }
  }
}

// Notifications

#notification-bar {
  transition: all 450ms;

  &.hidden {
    right: -100%;
  }

  header {
    padding: 30px 0 10px;
    margin-bottom: 30px;
    border-bottom: 2px solid $background;

    h2 {
      margin: 0;
      font-size: 22px;
      display: inline-block;
      position: relative;

      &:after {
        width: 100%;
        height: 2px;
        background-color: #000;
        position: absolute;
        left: 0;
        content: "";
        bottom: -12px;
      }
    }

    .close {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;

    &.middle {
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .notfound {
    color: $background;
    font-size: 32px;
  }
}

#menu-header {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.react-html5-camera-photo {
  > #display-error.display-error {
    width: 100%;

    h1 {
      font-size: 18px;
    }
  }

  > video {
    width: 100% !important;
    height: 300px;
    background: #000;
  }
}


.preview {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

#filters {
  position: relative;
  z-index: 10;

  .box {
    padding: 30px 0 15px 25px;
  }

  .button-date {
    border: 1px solid $grayMedium;
    padding: 16px 0;
    width: 100%;
    border-radius: 2px;
    text-align: left;
    position: relative;
    justify-content: flex-start;
    padding-left: 15px;

    span.label {
      position: absolute;
      top: -9px;
      background-color: $white;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      z-index: 1;
      left: 15px;
      color: #959595;
      font-weight: 500;
    }

    svg {
      position: absolute;
      right: 15px;
      top: 20px;
    }
  }

  .date-range-custom {
    position: relative;
  }

  .box-fl {
    background-color: $white;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.16);
    border-radius: 5px;
    transition: all 250ms;
    opacity: 0;
    pointer-events: none;
    
    .rdrDateRangePickerWrapper {
      border-radius: 5px;
      overflow: hidden;
    }

    &.active {
      top: 65px;
      opacity: 1;
      pointer-events: initial;
    }
  }
}
progress[value] {
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  max-width: 500px;
  height: 3px;
  border-radius: 10%;
  background-color: rgba(68, 76, 240, 0.23);
}
progress[value]::-webkit-progress-bar {
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  max-width: 500px;
  height: 3px;
  border-radius: 10%;
  background-color: rgba(68, 76, 240, 0.2);
}

progress[value]::-webkit-progress-value {
  background-color: rgba(52, 57, 85, 0.4);
  border-radius: 10%;
}

.token-input-field{
  width: 35px;
  border: none;
  border-bottom: 4px solid  #343955;
  font-size: 34px;
  text-align: center;
}

.reset-photo.MuiButton-root{
  margin-top: 16px;
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  border-radius: 20px;
  background-color: #D9D9D9;
  color: #343955;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    margin-top: 16px;
    outline: none;
    appearance: none;
    border: none;
    height: 40px;
    border-radius: 20px;
    background-color: #D9D9D9;
    color: #343955;
    font-size: 12px;
    cursor: pointer;
  
 }
}

.continuar-photo.MuiButton-root{
  outline: none;
  appearance: none;
  border: none;
  height: 40px;
  border-radius: 20px;
  background-color: #444cf0;
  color: white;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    outline: none;
    appearance: none;
    border: none;
    height: 40px;
    border-radius: 20px;
    background-color: #444cf0;
    color: white;
    font-size: 12px;
    cursor: pointer;

  }

}

.input-cpf-form{
  border-bottom: 1px solid $grayMedium;
  width: 260px;

}

.input-code-form{
  border: none;
  border-bottom: 1px solid $grayMedium;
  color: #343955;
  text-align: center;
  max-width: 170px;

}

.numero-disabled{
  background-color: $white;
}

/*filter div*/
.filtersDiv{
  display: flex;
  flex-direction: row;
  gap: 120px;
}
/*search box*/
.inputSearchBoxDiv{
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 16px;
  /*width: 200px;
  height: 32px;*/
  height: 36px;
  border: 1px solid $grayMedium;
  border-radius: 10px;

  i{
    width: 18px;
    height: 20px;
    align-self: center;
    color: $grayMedium;
    margin: 0;
    margin-right: 8px;
  }
}

.inputSearchBox{
  align-self: center;
  border: 0;
  outline: none;
  margin-left: 8px;
}
.inputSearchBox::placeholder{
}
/*fim search box*/

/*download csv*/
.downloadCsvDiv{
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}

/*fim download csv*/

/*select status*/

.selectStatus{
  height: 36px;
  width: 160px;
  margin-bottom: 16px;
}


